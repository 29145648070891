/* eslint-disable */

import { Button, Flex, Icon, Stack, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import { MdArrowLeft, MdArrowRight, MdChevronLeft, MdChevronRight } from "react-icons/md";
import * as React from "react";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
// Assets

export default function Pagination(props: {
	currentIndex: number;
	setCurrentIndex: Dispatch<SetStateAction<number>>;
	pageSize: number;
	totalLength: number;
	loadPreviousForPageSize?: () => void;
	loadPrevious: () => void;
	loadNext: () => void;
	loadNextForPageSize?: () => void;
	loadPage: (index: number) => void;
}) {
	const {
		currentIndex,
		setCurrentIndex,
		pageSize,
		totalLength,
		loadPreviousForPageSize,
		loadPrevious,
		loadNext,
		loadNextForPageSize,
		loadPage
	} = props;
	const { t } = useTranslation();

	const textColor = useColorModeValue('navy.700', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const brandColor = useColorModeValue('brand.500', 'brand.400');

	const maxPageCount = 5;

	const createPages = (count: number) => {
		let arrPageCount = [];

		if (count > maxPageCount) {
			for (
				let i = currentIndex + 1;
				i <= (currentIndex + maxPageCount < count ? currentIndex + maxPageCount : count);
				i++
			) {
				arrPageCount.push(i);
			}
		} else {
			for (let i = 1; i <= count; i++) {
				arrPageCount.push(i);
			}
		}

		return arrPageCount;
	};

	const canLoadPreviousPerSize = () => {
		if (currentIndex > 5) {
			return true;
		} else {
			return false;
		}
	};

	const canLoadPrevious = () => {
		if (currentIndex > 0) {
			return true;
		} else {
			return false;
		}
	};

	const canLoadNext = () => {
		if (totalLength > pageSize && currentIndex + 1 < totalLength / pageSize) {
			return true;
		} else {
			return false;
		}
	};

	const canLoadNextPerSize = () => {
		if (totalLength > pageSize && currentIndex + 5 < totalLength / pageSize) {
			return true;
		} else {
			return false;
		}
	};

	// console.log('======> totalCount / limit ' + loadNextForPageSize)

	return (
		<Flex w="100%" justifyContent="center" px="20px" pt="10px" pb="5px" mt="10px" alignItems={'center'}>
			{/* SET ROW NUMBER */}
			<Text
				fontSize="sm"
				color="gray.500"
				fontWeight="normal"
				mb={{ sm: '24px', md: '0px' }}
				mr={50}
				justifySelf={'flex-start'}
			>
				{/*Showing {pageSize * currentIndex + 1} to{' '}*/}
				{/*{pageSize * (currentIndex + 1) <= totalLength ? pageSize * (currentIndex + 1) : totalLength} of {totalLength}{' '}*/}
				{/*entries*/}
				{t('components.list_show_entries_total', {
					start: pageSize * currentIndex + 1,
					end: pageSize * (currentIndex + 1) <= totalLength ? pageSize * (currentIndex + 1) : totalLength,
					total: totalLength
				})}
			</Text>
			{/* PAGINATION BUTTONS */}
			<div className="flex items-center gap-2">
				<Stack direction="row" alignItems={'center'} justify="center" alignSelf="center" spacing="4px" ms="auto">
					{canLoadPreviousPerSize() && loadPreviousForPageSize ? (
						<Button
							variant="no-effects"
							onClick={() => loadPreviousForPageSize && loadPreviousForPageSize()}
							disabled={!canLoadPreviousPerSize()}
							transition="all .5s ease"
							w="40px"
							h="40px"
							borderRadius="50%"
							bg="transparent"
							border="1px solid"
							borderColor={useColorModeValue('gray.200', 'white')}
							display={pageSize === 5 ? 'none' : canLoadPrevious() ? 'flex' : 'none'}
							_hover={{
								bg: 'whiteAlpha.100',
								opacity: '0.7'
							}}
						>
							<Icon as={MdArrowLeft} w="16px" h="16px" color={textColor} />
						</Button>
					) : null}
					<Button
						variant="no-effects"
						onClick={() => loadPrevious()}
						disabled={!canLoadPrevious()}
						transition="all .5s ease"
						w="40px"
						h="40px"
						borderRadius="50%"
						bg="transparent"
						border="1px solid"
						borderColor={useColorModeValue('gray.200', 'white')}
						display={pageSize === 5 ? 'none' : canLoadPrevious() ? 'flex' : 'none'}
						_hover={{
							bg: 'whiteAlpha.100',
							opacity: '0.7'
						}}
					>
						<Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
					</Button>

					{createPages(totalLength / pageSize + (totalLength % pageSize > 0 ? 1 : 0)).map((pageNumber, index) => {
						return (
							<Button
								variant="no-effects"
								transition="all .5s ease"
								onClick={() => loadPage(pageNumber - 1)}
								w="40px"
								h="40px"
								borderRadius="50%"
								bg={pageNumber === currentIndex + 1 ? brandColor : 'transparent'}
								border={pageNumber === currentIndex + 1 ? 'none' : '1px solid lightgray'}
								_hover={
									pageNumber === currentIndex + 1
										? {
												opacity: '0.7'
										  }
										: {
												bg: 'whiteAlpha.100'
										  }
								}
								key={index}
							>
								<Text fontSize="sm" color={pageNumber === currentIndex + 1 ? '#fff' : textColor}>
									{pageNumber}
								</Text>
							</Button>
						);
					})}
					<Button
						variant="no-effects"
						onClick={() => loadNext()}
						disabled={!canLoadNext()}
						transition="all .5s ease"
						w="40px"
						h="40px"
						borderRadius="50%"
						bg="transparent"
						border="1px solid"
						borderColor={useColorModeValue('gray.200', 'white')}
						display={pageSize === 5 ? 'none' : canLoadNext() ? 'flex' : 'none'}
						_hover={{
							bg: 'whiteAlpha.100',
							opacity: '0.7'
						}}
					>
						<Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
					</Button>
					{canLoadNextPerSize() && loadNextForPageSize ? (
						<Button
							variant="no-effects"
							onClick={() => loadNextForPageSize && loadNextForPageSize()}
							disabled={!canLoadNextPerSize()}
							transition="all .5s ease"
							w="40px"
							h="40px"
							borderRadius="50%"
							bg="transparent"
							border="1px solid"
							borderColor={useColorModeValue('gray.200', 'white')}
							display={pageSize === 5 ? 'none' : canLoadNext() ? 'flex' : 'none'}
							_hover={{
								bg: 'whiteAlpha.100',
								opacity: '0.7'
							}}
						>
							<Icon as={MdArrowRight} w="16px" h="16px" color={textColor} />
						</Button>
					) : null}
				</Stack>
			</div>
		</Flex>
	);
}
