/**
 * 유저 검색 타입
 */
export enum MemberSearchType {
	ALL = 'ALL',
	ACTIVITY_NAME = 'ACTIVITY_NAME',
	PID = 'PID',
	REGISTER_DATE = 'REGISTER_DATE',
	MOBILE = 'MOBILE',
	EMAIL = 'EMAIL'
}

/**
 * 아이돌 검색 타입
 */
export enum IdolApplySearchType {
	ALL = 'ALL',
	ACTIVITY_NAME = 'ACTIVITY_NAME',
	PID = 'PID',
	REGISTER_DATE = 'REGISTER_DATE',
	MOBILE = 'MOBILE',
	EMAIL = 'EMAIL'
}

export enum IdolPreContractStatusType {}

/**
 * 유저 타입
 */
export enum MemberType {
	IDOL = 'IDOL',

	PRODUCER = 'PRODUCER'
}

/**
 * 유저 계정 상태
 * 일반, 블랙리스트, 탈퇴 회원
 */
export enum MemberStatusType {
	NORMAL = 'NORMAL',
	BLACK_LISTED = 'BLACK_LISTED',
	WITHDRAW_SUBMITTED = 'WITHDRAW_SUBMITTED'
}

/**
 * 회원 로그인 타입
 */
export enum MemberSocialLoginType {
	APPLE = 'APPLE',
	LINE = 'LINE',
	GOOGLE = 'GOOGLE',
	TWITTER = 'TWITTER'
}

/**
 * 아이돌승인상태
 */
export enum IdolApplyStatus {
	UNDER_REVIEW = 'UNDER_REVIEW',
	REJECTED = 'REJECTED',
	APPROVED = 'APPROVED'
}

/**
 * 아이돌승인상태
 */
// export enum IdolApplyStatus {
// 	UNDER_REVIEW = 'UNDER_REVIEW',
// 	REJECTED = 'REJECTED',
// 	APPROVED = 'APPROVED'
// }
/**
 * 라이브 스트리밍 검색 조건
 */
export enum LiveStreamingSearchType {
	ALL = 'ALL',
	IDOL_STAGE_NAME = 'IDOL_STAGE_NAME',
	IDOL_NAME = 'IDOL_NAME',
	LIVE_STREAMING_TITLE = 'LIVE_STREAMING_TITLE',
	LIVE_STREAMING_TAG = 'LIVE_STREAMING_TAG'
}

/**
 * 라이브 스트리밍 방속 상태
 * 전체 검색시 null
 */
export enum LiveStreamingBroadcastStatusType {
	SYSTEM_PROBLEM_END = 'SYSTEM_PROBLEM_END',
	ADMIN_FORCE_END = 'ADMIN_FORCE_END',
	ADMIN_FORCE_END_WAIT = 'ADMIN_FORCE_END_WAIT',
	EXPLODE_END = 'EXPLODE_END',
	EXPLODE_END_WAIT = 'EXPLODE_END_WAIT',
	NORMAL_END = 'NORMAL_END',
	NORMAL_END_WAIT = 'NORMAL_END_WAIT',
	LIVE = 'LIVE',
	READY = 'READY',
	CREATED = 'CREATED'
}

/**
 * 영상 종류
 */
export enum VideoContentType {
	LIVE_STREAMING = 'LIVE_STREAMING',
	VOD = 'VOD'
}

/**
 * 기간 종료
 */
export enum PeroidSearchCondition {
	ALL = 'ALL',
	WITHIN_7_DAY = 'WITHIN_7_DAY',
	WITHIN_14_DAY = 'WITHIN_14_DAY',
	WITHIN_30_DAY = 'WITHIN_30_DAY',
	WITHIN_90_DAY = ' WITHIN_90_DAY'
}

/**
 * 프로젝트 리그 종류
 */
export enum ProjectLeagueType {
	OFFICIAL = 'OFFICIAL',
	OFF_SEASON = 'OFF_SEASON'
}

/**
 * 프로젝트 상태
 */
export enum ProjectStatus {
	RECRUIT_SOON = 'RECRUIT_SOON',
	RECRUITING = 'RECRUITING',
	OPEN_SOON = 'OPEN_SOON',
	OPERATING = 'OPERATING',
	CLOSED = 'CLOSED'
}

/**
 * 프로젝트 지원자 상태
 */
export enum ProjectAppliedStatus {
	/** 지원가능 */
	AVAILABLE = 'AVAILABLE',
	/** 지원 완료 @ */
	APPLIED = 'APPLIED',
	/** 결과 대기 */
	WAITING = 'WAITING',
	/** 취소 @ */
	WITHDRAW = 'WITHDRAW',
	/** 합격 @ */
	QUALIFIED = 'QUALIFIED',
	/** 불합격 @ */
	UNQUALIFIED = 'UNQUALIFIED',
	/** 진행중: 합격+클리어x */
	IN_PROGRESS = 'IN_PROGRESS',
	/** 결과: 합격+클리어o @ */
	CLEARED = 'CLEARED',
	/** 결과: 합격+클리어x */
	CLOSED = 'CLOSED'
}

export enum ProjectApplicantResultStatus {
	QUALIFIED = 'QUALIFIED',
	UNQUALIFIED = 'UNQUALIFIED'
}

/**
 * 프로젝트 목록 정렬 순서
 */
export enum ListDirection {
	ASC = 'ASC',
	DESC = 'DESC'
}

/**
 * 미션 완료 조건 타입
 */
export enum MissionActionType {
	LIVE = 'LIVE',
	LIVE_REACTION = 'LIVE_REACTION',
	LIVE_CHAT_POST = 'LIVE_CHAT_POST',
	LIVE_CHAT_GET = 'LIVE_CHAT_GET',
	LIVE_DONATION = 'LIVE_DONATION',
	LIVE_VOTE = 'LIVE_VOTE',
	OTHER_LIVE_WATCH = 'OTHER_LIVE_WATCH',
	OTHER_LIVE_DONATION = 'OTHER_LIVE_DONATION',
	OTHER_LIVE_CHAT = 'OTHER_LIVE_CHAT',
	OTHER_VOD_COMMENT = 'OTHER_VOD_COMMENT'
}

/**
 * 미션 완료 조건 갯수 타입
 */
export enum MissionActionGoalType {
	COUNT = 'COUNT',
	EVALUATION = 'EVALUATION',
	TOTAL_DONATION = 'TOTAL_DONATION',
	MIN_OVER_DONATION = 'MIN_OVER_DONATION',
	STRING_LENGTH = 'STRING_LENGTH',
	RUNNING_TIME = 'RUNNING_TIME',
	LIMIT_TIME = 'LIMIT_TIME'
}

/**
 * 보상 타입
 */
export enum RewardType {
	NONE = 'NONE',
	SKILL = 'SKILL',
	CHARACTER = 'CHARACTER'
}

export enum RewardPointType {
	P90 = '90',
	P80 = '80',
	P70 = '70',
	P60 = '60',
	P50 = '50',
	P40 = '40',
	P30 = '30',
	P20 = '20',
	P10 = '10',
	P0 = '0'
}

/**
 * 매력 타입
 */
export enum CharacterType {
	CUTE = 'CUTE',
	COOL = 'COOL',
	FRESH = 'FRESH',
	SEXY = 'SEXY',
	NICE = 'NICE'
}

/**
 * 스킬 타입
 */
export enum SkillType {
	MUSIC = 'MUSIC',
	DANCE = 'DANCE',
	PERFORMANCE = 'PERFORMANCE',
	PHYSICAL = 'PHYSICAL',
	COMMUNICATION = 'COMMUNICATION'
}

/**
 * 통화 타입
 */
export enum CurrencyType {
	NONE = 'NONE',
	HYPY_POINT = 'HYPY_POINT',
	HYPY_GOLD = 'HYPY_GOLD'
}

/**
 * 상품 타입
 */
export enum ProductType {
	NONE = 'NONE',
	CONSUMABLE = 'CONSUMABLE',
	NON_CONSUMABLE = 'NON_CONSUMABLE'
}

/**
 * 상품 이벤트 타입
 */
export enum ProductEventType {
	NONE = 'NONE',
	NEW_USER = 'NEW_USER',
	MANUAL = 'MANUAL'
}

/**
 * 정렬 타입
 */
export enum SortCondition {
	NEWEST_DEST = 'NEWEST_DEST',
	WORD_ASC = 'WORD_ASC',
	ASC = 'ASC',
	DESC = 'DESC'
}

/**
 * 테이블 Row 타입
 */
export enum TableRowType {
	TEXT = 'TEXT',
	IMAGE = 'IMAGE',
	DATETIME = 'DATETIME',
	BOOLEAN = 'BOOLEAN',
	BUTTON = 'BUTTON',
	ACTION = 'ACTION',
	ENUM = 'ENUM'
}

export enum ReportContentsType {
	ALL = 'ALL',
	PROFILE_IDOL = 'PROFILE_IDOL',
	PROFILE_PRODUCER = 'PROFILE_PRODUCER',
	LIVE_STREAMING = 'LIVE_STREAMING',
	VOD = 'VOD',
	VOD_COMMENT = 'VOD_COMMENT',
	LIVE_STREAMING_CHAT = 'LIVE_STREAMING_CHAT', // 검색용
	LIVE_CHATTING = 'LIVE_CHATTING' // 목록 응답용
}

export enum ReportStatusType {
	NONE = 'NONE',
	// ("어드민 처리 진행중")
	PENDING = 'PENDING',
	// "어드민에서 유효 처리함")
	VALID = 'VALID',
	// ("어드민에서 무효 처리함")
	INVALID = 'INVALID',
	// ("철회 처리 함")
	REVERTED = 'REVERTED'
}

export enum ReportStatusProcessingType {
	// "어드민에서 유효 처리함")
	VALID = 'VALID',
	// ("어드민에서 무효 처리함")
	INVALID = 'INVALID'
}

export enum ReportStatusResultType {
	// ("철회 처리 함")
	REVERTED = 'REVERTED'
}

/**
 * 공지 타입
 */
export enum NoticeType {
	SYSTEM = 'SYSTEM',
	ADVERTISEMENT = 'ADVERTISEMENT'
}

/**
 * 공지 노출 위치 타입
 */
export enum NoticePlacementType {
	HOME = 'HOME',
	LOOK_AROUND = 'LOOK_AROUND'
}

export enum EventRewardType {
	NONE = 'NONE',
	POINT = 'POINT',
	ITEM = 'ITEM'
}

export enum EventRewardPayType {
	NONE = 'NONE',
	SEQUENCE = 'SEQUENCE',
	TERM = 'TERM'
}

export enum OSType {
	iOS = 'iOS',
	aOS = 'aOS'
}

export enum SanctionType {
	CHAT_COMMENT_WARNING = 'CHAT_COMMENT_WARNING',
	SYNTHESIS_WARNING = 'SYNTHESIS_WARNING',
	CHAT_COMMENT_SUSPENSIONS = 'CHAT_COMMENT_SUSPENSIONS',
	ACTIVITY_SUSPENSIONS = 'ACTIVITY_SUSPENSIONS',
	USING_SUSPENSIONS = 'USING_SUSPENSIONS'
}
