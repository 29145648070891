import { BaseApi } from "./baseAPI";
import { PagingRequest } from "./commonAPI";
import {
	CurrencyType,
	MemberSearchType,
	MemberSocialLoginType,
	MemberStatusType,
	MemberType,
	SanctionType
} from "../types/enums";
import {
	URL_MEMBER_BLACKLISTED,
	URL_MEMBER_BLACKLISTED_DETAIL,
	URL_MEMBER_CURRENCY_DEPOSIT,
	URL_MEMBER_DETAIL,
	URL_MEMBER_SEARCH
} from "./URLS";

/**
 * Common Controller
 */
export class MemberAPI extends BaseApi {
	getMemberListSearch = (request: MemberListSearchRequest) => {
		return this.axios
			.get<MemberListSearchResponse>(URL_MEMBER_SEARCH, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	memberDetail = (memberId: number, request: MemberDetailRequest) => {
		return this.axios
			.get<MemberDetailResponse>(URL_MEMBER_DETAIL.replace('{memberId}', memberId.toString()), { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	blacklisted = (request: PagingRequest) => {
		return this.axios
			.get<MemberBlacklistResponse>(URL_MEMBER_BLACKLISTED, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	addBlacklisted = (memberId: number, request: MemberBlackListedRequest) => {
		return this.axios
			.post(URL_MEMBER_BLACKLISTED_DETAIL.replace('{memberId}', memberId.toString()), request)
			.then(response => response)
			.catch(error => error.response);
	};

	deleteBlacklisted = (memberId: number, request: MemberBlackListedRequest) => {
		return this.axios
			.delete(URL_MEMBER_BLACKLISTED_DETAIL.replace('{memberId}', memberId.toString()), { data: request })
			.then(response => response)
			.catch(error => error.response);
	};

	postCurrencyDeposit = (request: MemberCurrencyRequest) => {
		return this.axios
			.post(URL_MEMBER_CURRENCY_DEPOSIT, request)
			.then(response => response)
			.catch(error => error.response);
	};
}

/**
 * ====================================================
 * ====================================================
 * Request
 */
export type MemberListSearchRequest = PagingRequest & {
	/**
	 *  string
	 *  (query)
	 *  회원 검색 조건 종류
	 */
	searchType?: MemberSearchType;

	/**
	 * string
	 *  (query)
	 *  검색어
	 */
	searchWord?: string;

	/**
	 * string - yyyyMMdd
	 *  (query)
	 *  필터 - 가입일자(시작)
	 */
	registerDateStart?: string;

	/**
	 * string - yyyyMMdd
	 *  (query)
	 *  필터 - 가입일자(종료)
	 */
	registerDateEnd?: string;

	/**
	 * string
	 *  (query)
	 *  필터 - 직업
	 */

	memberType?: string;

	/**
	 * string
	 *   (query)
	 *   필터 - 계정상태
	 */
	memberStatus?: MemberStatusType;

	/**
	 * string
	 *   (query)
	 *   필터 - 연동 소셜
	 */
	loginType?: MemberSocialLoginType;
};

export type MemberDetailRequest = {
	memberType: MemberType;
};

export type MemberBlackListedRequest = {
	memberType: MemberType;
	blackListStartDate?: string;
	blackListEndDate?: string;
	registerReason?: string;
	deRegisterReason?: string;
};

export type MemberCurrencyRequest = {
	/**
	 * 클라이언트측 랜덤 id
	 */
	clientTxId: string;

	/**
	 * minimum: 1
	 *  멤버 id
	 */
	memberId: string;

	/**
	 * 멤버 유형
	 * Enum:
	 *    [ IDOL, PRODUCER ]
	 */
	memberType: MemberType;

	/**
	 * 지급하는 재화 타입
	 * Enum:
	 *    [ HYPY_POINT, HYPY_GOLD ]
	 */
	currencyType: CurrencyType;

	/**
	 * minimum: 1
	 *  지급할 액수
	 */
	depositAmount: number;
};

/**
 * ====================================================
 * ====================================================
 * Response
 */
export type MemberListSearchResponse = {
	memberTrain: MemberTrain[];
	count: number;
};

export type MemberBlacklistResponse = {
	blackListTrain: BlacklistTrain[];
	count: number;
};

export type MemberDetailResponse = {
	// 회원 아이디
	memberId: number;

	// 프로필 사진 URL
	profileImgUrl: string;

	// 활동명
	activityName: string;

	// 회원 식별자
	pid: string;

	// 가입일자
	createDateTime: string;

	// 직업
	// Enum:
	//     [IDOL, PRODUCER]
	memberType: MemberType;

	// 전화번호 - 국가번호
	countryCode: string;

	// 전화번호
	mobile: string;

	// 이메일
	email: string;

	// 계정 상태
	// Enum:
	// [NORMAL, BLACK_LISTED, WITHDRAW_SUBMITTED]
	memberStatus: MemberStatusType;

	// 서비스 이용약관 동의 여부
	isTermsOfService: boolean;

	// 서비스 이용약관 동의일
	termsOfServiceDate: string;

	// 방송 수행 횟수
	liveStreamingCount: number;

	// 업로드된 재방송VOD 개수
	vodCount: number;

	// 아이돌 명함 담은 개수
	idolCardCount: number;

	// 현재 STIRKE 단계
	strikeStatus: string;

	// 최근 로그인 일자
	lastSignInDateTime: string;

	// 후원 개수
	donationCount: number;

	// 본인 인증 여부
	isVerified: boolean;

	// 이용 정지 여부
	blackListed: boolean;

	// 이용 정지 시작 일자
	blackListStartDateTime: string;

	// 이용 정지 만료 일자
	blackListEndDateTime: string;

	// 채팅/댓글 경고 종료 일자
	chatCommentWarningExpireDateTime: string;

	// 종합 경고 종료 일자
	synthesisWarningExpireDateTime: string;

	// 채팅 금지 경고 시작 일자
	lastChatCommentSanctionsStartDateTime: string;

	// 채팅 금지 경고 종료 일자
	lastChatCommentSanctionsExpireDateTime: string;

	// 마지막으로 받은 채팅 스트라이크 단계
	lastChatCommentStrikeLevel: number;

	// 마지막으로 받은 활동 정지 시작 일자
	lastActivitySanctionsStartDateTime: string;

	// 마지막으로 받은 활동 정지 종료 일자
	lastActivitySanctionsExpireDateTime: string;

	// 마지막으로 받은 종합 경고 스트라이크 단계
	lastSynthesisStrikeLevel: number;

	sanctionHistoryTrain?: SanctionHistory[];

	needExposePopupTrain?: NeedExposePopup;

	lastSanctionHistory?: SanctionHistory;
};

export type MemberTrain = {
	/**
	 * 회원 아이디
	 */
	memberId: number;

	/**
	 * 활동명
	 */
	activityName: string;

	/**
	 * 회원 식별자
	 */
	pid: string;

	/**
	 * 가입일자
	 */
	createDateTime: string;

	/**
	 * 직업
	 */

	memberType: MemberType;

	/**
	 * 전화번호 - 국가번호
	 */
	countryCode: string;

	/**
	 * 전화번호
	 */
	mobile: string;

	/**
	 * 이메일
	 */
	email: string;

	/**
	 * 연동 소셜
	 */
	loginType: MemberSocialLoginType;

	/**
	 * 계정 상태
	 *
	 *   Enum:
	 *     [ NORMAL, BLACK_LISTED, WITHDRAW_SUBMITTED ]
	 */
	memberStatus: MemberStatusType;

	/**
	 * 본인 인증 여부
	 */
	isVerified: boolean;

	/**
	 * 탈퇴 여부 (회원 탈퇴시에 $set)
	 */
	withdrawSubmitted: boolean;

	/**
	 * 블랙 리스트
	 */
	blackListed: boolean;

	/**
	 * 인 앱 결제 여부
	 */
	inAppPurchased: boolean;
};

// 회원 블랙 리스트 상세
export type BlacklistTrain = {
	// 회원 종류
	memberType: MemberType;

	//	회원 아이디
	memberId: number;

	// 블랙 리스트
	blackListed: boolean;

	// 블랙 리스트 추가 어드민
	registerAdminAccountId: number;

	// 블랙 리스트 추가 사유
	registerReason: string;

	// 활동명
	activityName: string;

	// pid
	pid: string;

	// 블랙 리스트 적용기간 시작
	blackListStartDateTime: string;

	// 블랙 리스트 적용기간 종료
	blackListEndDateTime: string;
};

// 회원 제재 타입
export type SanctionHistory = {
	level: number;
	type: SanctionType;
	sanctionStartDateTime: string;
	sanctionExpireDateTime: string;
	createDateTime: string;
};

// 		멤버 앱 최초 진입시 팝업 노출이 필요한 경우
export type NeedExposePopup = {
	// 메시지 코드
	messageCode: string;

	// 제목
	title: string;

	// 추가 변수
	titleArgs: string[];

	// 내용
	body: string;

	// 내용 변수
	bodyArgs: string[];

	// 추가 값
	additionalArg: string[];
};
